<template>
    <section>
        <modal ref="modalCrearUsuario" titulo="Crear Usuario" icon="group" @guardar="crear_usuario">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-center">
                    <slim-cropper ref="cropChat" :options="slimOptions" class="border rounded-circle" style="height:120px;width:120px;background:#F8F9FF;">
                        <div slot="label" class="">
                            <img src="/img/modales/camera.svg" alt="" />
                        </div>
                    </slim-cropper>
                    <small>Foto</small>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <ValidationObserver ref="validacion">
                    <div class="col-12 px-5 mt-3">
                        <label class="">
                            Nombre del Usuario
                        </label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre usuario">
                            <el-input v-model="model.nombre" maxlength="30" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 px-5 mt-3">
                        <label class="">
                            Correo Electronico
                        </label>
                        <div class="" style="width:391px;">
                            <ValidationProvider v-slot="{ errors, failedRules }" rules="required|email" name="correo electrónico">
                                <el-input v-model="model.correo" type="email">
                                    <i v-if="failedRules.email" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    <i v-else slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                                </el-input>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 px-5 mt-3">
                        <label class="">
                            Contraseña
                        </label>
                        <div class="" style="width:391px;">
                            <ValidationProvider v-slot="{ errors }" vid="password" :rules="reglas.contrasena" name="Contraseña nueva">
                                <el-input v-model="model.password" :type="ver_pass?'text':'password'">
                                    <i v-if="!ver_pass" slot="suffix" class="icon-eye text-muted cr-pointer el-input__icon f-17" @click="ver_pass=true" />
                                    <i v-else slot="suffix" class="icon-eye-off text-muted cr-pointer el-input__icon f-17" @click="ver_pass=false" />
                                </el-input>
                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                <p class="text-muted f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 px-5 mt-3">
                        <label class="">
                            Confirmar Contraseña
                        </label>
                        <div class="" style="width:391px;">
                            <ValidationProvider v-slot="{ failedRules, errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                                <el-input v-model="model.pass_confirmar" type="password">
                                    <i v-if="failedRules.confirmed && model.password !== ''" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    <i v-else slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                                </el-input>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 d-middle justify-content-star px-5 my-2">
                        <el-checkbox v-model="model.superadmin" class="ml-4">
                            Super Administrador
                        </el-checkbox>
                        <el-tooltip class="mx-2" placement="bottom" effect="light" content="Puedo Eliminar cualquier usuario excepto asi mismo">
                            <i class="icon-info-circled-alt f-20 text-gr-general" />    
                        </el-tooltip>
                    </div>
                </ValidationObserver>
            </div>
        </modal>
    </section>
</template>
<script>
import Usuarios from "~/services/usuarios/index"
import Aws from '~/services/aws'

export default {
    data(){
        return{
            ver_pass: false,
            model: {
                id: null,
                nombre: '',
                correo: '',
                password: '',
                imagen: '',
                pass_confirmar: '',
                superadmin: false
            },
            reglas: {
                contrasena: {
                    min: 8,
                    regex: /^(?=.*\d)(?=.*[a-zA-Z]?)[a-zA-Z0-9]{8,200}$/
                }
            },
            slimOptions: {
                ratio: '1:1',
                label: 'Subir',
                // size: { width: 250, height: 250 },
                // minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
        }
    },
    methods: {
        toggle(datos){
            console.log(datos);
            if (datos){
                this.model.id = datos.id
                this.model.nombre = datos.nombre
                this.model.correo = datos.correo
                this.model.superadmin = datos.tipo == 3
                this.model.imagen = datos.imagen
                if (datos.imagen){
                    this.$refs.cropChat.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${datos.imagen}`)
                } else {
                    this.$refs.cropChat.instanciaCrop.remove()
                }
            }
            this.$refs.modalCrearUsuario.toggle();
        },
        async crear_usuario(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(valid){
                    const archivo = this.$refs.cropChat.instanciaCrop.dataBase64.output
                    if(!archivo){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                    this.model.imagen = archivo.image
                    const {data} = await Usuarios.crear_usuario(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.model = {
                        nombre: '',
                        correo: '',
                        password: '',
                        imagen: '',
                        pass_confirmar: '',
                        superadmin: false
                    }
                    this.$refs.modalCrearUsuario.toggle()
                    this.$refs.validacion.reset()
                    this.$refs.cropChat.instanciaCrop.remove()
                    this.$emit('actualizar')
                }else{
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>